import { includes } from 'ramda'

import { LearningSeriesType } from '../../graphql/learning'
import {
  SubscriptionDiscountType,
  SubscriptionDiscountTypeEnum,
} from '../../types/subscriptionTypes'

export const calculatePrices = (series: LearningSeriesType, price: string) => {
  const currency = series?.subscription_settings?.currency_code ?? 'GBP'

  return {
    price: getPriceString(price, currency),
    discountedPrice: series?.applicable_discount
      ? getDiscountPrice(price, currency, series.applicable_discount)
      : null,
  }
}

export const getPriceString = (price: string, currency: string) => {
  const currencySymbol = {
    GBP: '£',
    USD: '$',
    EUR: '€',
  }

  return Number.isNaN(parseFloat(price))
    ? '-'
    : `${currencySymbol[currency.toUpperCase()] ?? '$'}${parseFloat(
        price
      ).toFixed(2)}`
}

const getDiscountPrice = (base: string, currency: string, settings: any) => {
  if (Number.isNaN(parseFloat(base))) return '-'

  if (
    includes(settings?.discount_type, [
      SubscriptionDiscountType.FreeAccess,
      SubscriptionDiscountType.PaidByPartner,
    ])
  )
    return getPriceString('0', currency)

  const result = (
    parseFloat(base) -
    parseFloat(base) * (parseInt(settings?.discount_percentage) / 100)
  ).toFixed(2)

  return Number.isNaN(parseFloat(result))
    ? getPriceString(base, currency)
    : getPriceString(result, currency)
}

export const hasFreeAccess = (discountType: SubscriptionDiscountTypeEnum) =>
  includes(discountType, [
    SubscriptionDiscountType.FreeAccess,
    SubscriptionDiscountType.PaidByPartner,
  ])
