import React from 'react'
import cs from 'classnames'
import { LearningSeriesWithModulesType } from '../../../../graphql/learning'
import { isNativeAppBrowser } from '../../../../lib/client-info'

interface Props {
  ix: number
  series: LearningSeriesWithModulesType
  onViewModules: () => void
  commonRowData: Record<string, any>
}

const SeriesRowTable = ({
  ix,
  series,
  onViewModules,
  commonRowData,
}: Props): JSX.Element => {
  const isNative = isNativeAppBrowser()

  return (
    <tr
      className={cs('fs-unmask learning-tbl__tr', {
        'learning-tbl__tr--even': ix % 2 === 0,
      })}
      style={{ cursor: 'pointer' }}
      onClick={onViewModules}
    >
      {isNative ? null : (
        <td>
          <img
            src={commonRowData.coverImage}
            width={40}
            height={40}
            style={{ objectFit: 'cover' }}
            alt={'Series Cover Image'}
          />
        </td>
      )}
      <td>{series.title}</td>
      <td style={{ whiteSpace: 'nowrap' }}>{series.modules?.length} modules</td>
      <td>
        <button className="btn" style={{ width: '100%' }}>
          View details
        </button>
      </td>
    </tr>
  )
}

export default SeriesRowTable
