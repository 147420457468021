export enum CurrentUserModuleStatus {
  completed = 'completed',
  inProgress = 'inProgress',
  null = 'null',
}

export enum StatusType {
  Completed = 'completed',
  InProgress = 'in-progress',
  Authored = 'authored',
  Subscribed = 'subscribed',
}

export enum ViewType {
  cards = 'cards',
  table = 'table',
}
