import React from 'react'

import { LearningSeriesWithModulesType } from '../../../../graphql/learning'
import { getImageURL } from '../../../../url'
import SeriesRowCard from './SeriesRowCard'
import SeriesRowTable from './SeriesRowTable'
import { ViewType } from './types'

export const PLACEHOLDER_IMG_SRC = '/static/media/images/medshr-icon-120@3x.png'

interface Props {
  ix: number
  viewType: ViewType
  series: LearningSeriesWithModulesType
  onViewModules: () => void
}

const SeriesRow = ({
  ix,
  viewType,
  series,
  onViewModules,
}: Props): JSX.Element => {
  const commonRowData = {
    coverImage: series.image
      ? getImageURL('small', 'cms', encodeURI(series.image.path))
      : PLACEHOLDER_IMG_SRC,
  }

  if (viewType === ViewType.table) {
    return (
      <SeriesRowTable
        ix={ix}
        key={series.id}
        series={series}
        onViewModules={onViewModules}
        commonRowData={commonRowData}
      />
    )
  }

  if (viewType === ViewType.cards) {
    return (
      <SeriesRowCard
        key={series.id}
        series={series}
        commonRowData={commonRowData}
      />
    )
  }
}

export default SeriesRow
