import React from 'react'
import {
  calculatePrices,
  hasFreeAccess,
} from '../../components/Subscriptions/calculatePrices'
import {
  LearningModuleWithSessionType,
  LearningSeriesType,
} from '../../graphql/learning/types'
import { getModulePrice } from '../../graphql/learning/price'

interface Props {
  module?: LearningModuleWithSessionType
  series: LearningSeriesType
  cardType?: 'module' | 'series'
}

const svgIcons = {
  GBP: '/static/media/svg/subscription_gbp_icon.svg',
  USD: '/static/media/svg/subscription_usd_icon.svg',
  EUR: '/static/media/svg/subscription_eur_icon.svg',
  subscribed: '/static/media/svg/subscription_subscribed_icon.svg',
}

export const SubscriptionCardIcon = ({ series, module, cardType }: Props) => {
  const {
    payments_enabled,
    subscription_settings,
    applicable_discount,
    has_subscription,
  } = series
  const { has_subscription: hasModuleSubscription } = module ?? {}

  const {
    currency_code: currencyCode,
    available_as_module,
    available_as_series,
    module_price,
    series_price,
  } = subscription_settings ?? {}

  const subscribed = hasModuleSubscription || has_subscription
  const freeAccess =
    payments_enabled &&
    ((available_as_module &&
      ((module?.price ?? module_price) === 0 ||
        hasFreeAccess(applicable_discount?.discount_type))) ||
      (available_as_series &&
        (series_price === 0 ||
          hasFreeAccess(applicable_discount?.discount_type))))
  if (!payments_enabled || freeAccess) return label('Free')
  if (subscribed) return svgLabel(svgIcons.subscribed)

  const priceProps = {
    series,
    modulePrice: module ? getModulePrice(series, module) : module_price,
    seriesPrice: series_price,
  }

  const {
    priceString,
    discountedPrice,
    seriesPriceString,
    seriesDiscountedPrice,
  } = usePriceCalculation(priceProps)

  const iconSrc = svgIcons[currencyCode] || svgIcons['GBP']

  const getContent = () => {
    if (available_as_module && cardType === 'module') {
      return label(applicable_discount ? discountedPrice : priceString)
    }

    if (available_as_series && cardType === 'series') {
      return label(
        applicable_discount ? seriesDiscountedPrice : seriesPriceString
      )
    }

    if (available_as_series && cardType === 'module') {
      return svgLabel(iconSrc)
    }

    return null
  }

  return <div>{getContent()}</div>
}

const label = (text = 'Free') => (
  <div className="price-label-container">
    <span className="price-label-text">{text}</span>
  </div>
)

const svgLabel = (src: string) => (
  <img
    src={src}
    style={{
      background: 'transparent',
      zIndex: 100,
      position: 'absolute',
      bottom: '.5rem',
      right: 0,
    }}
    alt="Subscription enabled"
  />
)

const usePriceCalculation = ({ series, modulePrice, seriesPrice }) => {
  return React.useMemo(() => {
    const { price: priceString, discountedPrice } = calculatePrices(
      series,
      modulePrice
    )
    const { price: seriesPriceString, discountedPrice: seriesDiscountedPrice } =
      calculatePrices(series, seriesPrice)
    return {
      priceString,
      discountedPrice,
      seriesPriceString,
      seriesDiscountedPrice,
    }
  }, [series, modulePrice, seriesPrice])
}

export default SubscriptionCardIcon
