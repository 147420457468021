import {
  ModuleResponseAndCompletionFragment,
  SeriesResponseFragment,
} from '../../graphql/operations'
import { SubscriptionType } from './types'

export const getActiveSub = (
  series: SeriesResponseFragment,
  module: ModuleResponseAndCompletionFragment
) => {
  if (module?.has_subscription) {
    return module.active_subscription?.[0]
  }

  if (series.has_subscription) {
    return series.active_subscription?.[0]
  }
}

export const getSubType = (isSubscription: boolean, subscriptionSettings) => {
  if (isSubscription && subscriptionSettings?.available_as_module) {
    return SubscriptionType.MODULE
  }

  if (isSubscription && subscriptionSettings?.available_as_series) {
    return SubscriptionType.SERIES
  }
}
