import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import formatISO from 'date-fns/formatISO'

import parseISO from 'date-fns/parseISO'
import React from 'react'
import { useTimezone } from '../../lib/use-timezone'

const tryElse = function <T>(attemptThis: () => T, orElse: () => T) {
  try {
    return attemptThis()
  } catch (e) {
    return orElse()
  }
}

const FormattedDate = ({
  date,
  className,
  timeZone,
  format: formatString,
}: {
  date: string | Date
  className?: string
  timeZone?: string
  format: string
}) => {
  const dateObject = typeof date === 'string' ? parseISO(date) : date
  timeZone = timeZone || useTimezone()
  const utcDate = tryElse(
    () => toDate(dateObject),
    () => dateObject
  )
  const zonedDate = tryElse(
    () => utcToZonedTime(utcDate, timeZone),
    () => utcDate
  )
  const localDateString = tryElse(
    () => format(zonedDate, formatString),
    () => format(dateObject, formatString)
  )
  return (
    <time dateTime={formatISO(dateObject)} className={className}>
      {localDateString}
    </time>
  )
}

const DateView = ({
  date,
  className,
  timeZone,
  format = 'medium',
  withTime,
}: {
  date: string | Date
  className?: string
  timeZone?: string
  format?: 'numeric' | 'short' | 'medium' | 'long'
  withTime?: boolean
}) => {
  // TODO: use localized formats. need to dynamically load date-fns locale.
  const dateFormats = {
    numeric: 'dd/MM/yy',
    short: 'd MMM yyyy',
    medium: 'do MMM yyyy',
    long: 'do MMMM yyyy',
  }
  const timeFormats = {
    numeric: ' H:mm',
    short: ', HH:mm',
    medium: " 'at' HH:mm",
    long: " 'at' HH:mm",
  }
  const dateFormat = dateFormats[format]
  const timeFormat = withTime ? timeFormats[format] : ''
  return (
    <FormattedDate
      date={date}
      className={className}
      timeZone={timeZone}
      format={`${dateFormat}${timeFormat}`}
    />
  )
}

export default DateView
