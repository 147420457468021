import cs from 'classnames'
import Link from 'next/link'
import React from 'react'

import { qualifyUrl } from '../../../url'
import { Button } from '../../../ui/buttons'
import { CartItem } from '../PreSalesPage'
import { SubscriptionType } from '../types'
import { useRouter } from 'next/router'
import { useAuthenticationState } from '../../../modules/auth/hooks'

interface Props {
  productID?: string
  type?: SubscriptionType
  availableAsSeries?: boolean
  seriesID?: string
  libraryID: string
  label?: string
  presale?: boolean
  items?: CartItem[]
  disabled?: boolean
  fill?: boolean
  block?: boolean
  fillColour?: string
}

export const SubscribeButton = ({
  productID, // Either module or series ID
  type,
  availableAsSeries,
  seriesID,
  libraryID,
  label = 'Subscribe',
  presale = true,
  items,
  disabled = false,
  fill = true,
  block = true,
  fillColour,
}: Props) => {
  const router = useRouter()
  const auth = useAuthenticationState()

  if (!auth.isAuthenticated) {
    return (
      <a href={`/join?referer=${router.asPath}`}>
        <Button
          primary
          className={cs(`btn ${fill && 'btn--fill'} ${block && 'btn--block'}`)}
        >
          <span>{label}</span>
        </Button>
      </a>
    )
  }

  const cancelUrl = `/learning/groups/${libraryID}`
  const returnUrl = '/learning/success/'

  const shouldPreSale =
    type === SubscriptionType.MODULE && availableAsSeries && presale

  if (shouldPreSale) {
    const preSalePage = `/learning/presale/${seriesID}/${productID}`
    return (
      <Link href={preSalePage} legacyBehavior>
        <Button
          primary
          className={cs(`btn ${fill && 'btn--fill'} ${block && 'btn--block'}`)}
          disabled={disabled}
        >
          <span>{label}</span>
        </Button>
      </Link>
    )
  }

  return (
    <>
      <form
        method="POST"
        action={qualifyUrl('/api/stripe/subscribe/learning/checkout')}
      >
        <input type="hidden" name="return_url" value={returnUrl} />
        <input type="hidden" name="cancel_url" value={cancelUrl} />
        {items ? (
          items.map((item, i) => (
            <React.Fragment key={item.id}>
              <input type="hidden" name={`items[${i}][id]`} value={item.id} />
              <input
                type="hidden"
                name={`items[${i}][type]`}
                value={item.type}
              />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <input type="hidden" name={`items[0][id]`} value={productID} />
            <input type="hidden" name={`items[0][type]`} value={type} />
          </React.Fragment>
        )}
        <Button
          type="submit"
          primary
          className={cs(`btn ${fill && 'btn--fill'} ${block && 'btn--block'}`)}
          disabled={disabled}
          inlineStyle={{
            backgroundColor: fillColour,
            borderColor: fillColour,
          }}
        >
          {label}
        </Button>
      </form>
    </>
  )
}
