import { useCookie } from './use-cookies'

const defaultTimezone = 'Europe/London'

export const useTimezone = () => {
  let tz = useCookie('mstz')
  if (tz) return tz

  if (typeof document !== 'undefined') {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch {}
  }
  return defaultTimezone
}
